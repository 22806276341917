<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="userForm.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                name="Type"
              >
                <b-form-group
                  label="Type"
                  label-for="Type"
                >
                  <b-form-select
                    id="Type"
                    v-model="userForm.type"
                    :options="typeOptions"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Discount Amount / Type">
                <b-input-group>
                  <validation-provider
                    #default="validationContext"
                    name="Discount Amount"
                    rules="required"
                  >
                    <b-form-input
                      v-model.number="userForm.amount"
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="Discount Amount"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="ZeroCash Type"
                    rules="required"
                  >
                    <b-input-group-append>
                      <b-form-select
                        v-model="userForm.amount_type"
                        :options="amountpercentage"
                        :state="getValidationState(validationContext)"
                        right
                        value-field="value"
                        text-field="text"
                      >
                        <template #first>
                          <b-form-select-option
                            disabled
                            value=""
                          >
                            Select Type
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Expire Event date"
                rules="required"
              >
                <b-form-group
                  label="Expire Event date"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="userForm.event_expire_date"
                    :state="getValidationState(validationContext)"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                name="Expire Days"
              >
                <b-form-group
                  label="Expire Days"
                  label-for="expire_days"
                >
                  <b-form-input
                    id="expire_days"
                    v-model.number="userForm.expire_days"
                    trim
                    type="number"
                    placeholder="Expire Days"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                name="Quantity"
              >
                <b-form-group
                  label="Quantity"
                  label-for="QL"
                >
                  <b-form-input
                    id="QL"
                    v-model.number="userForm.quantity"
                    trim
                    type="number"
                    placeholder="Quantity"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="User Limit"
                rules="required"
              >
                <b-form-group
                  label="User Limit"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.user_limit"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="User Limit"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="validationContext"
                name="Amount Min"
                rules="required"
              >
                <b-form-group
                  label="Amount Min"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.min_amount"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="100"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="validationContext"
                name="Amount Max"
                rules="required"
              >
                <b-form-group
                  label="Amount Max"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.max_amount"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="500"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="userForm.type == 1"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Min Deposit"
                rules="required"
              >
                <b-form-group
                  label="Min Deposit"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.min_deposit"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="100"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="userForm.type == 1"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Max Deposit"
                rules="required"
              >
                <b-form-group
                  label="Max Deposit"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.max_deposit"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="500"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="userForm.type == 2"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Transaction Number"
                rules="required"
              >
                <b-form-group
                  label="Transaction Number"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.transaction_no"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="100"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="userForm.type == 2"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Transaction Min Value"
                rules="required"
              >
                <b-form-group
                  label="Transaction Min Value"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.transaction_mini_value"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="500"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="userForm.type == 2"
              md="6"
            >
              <validation-provider
                name="Transaction Type"
              >
                <b-form-group
                  label="Transaction Type"
                  label-for="TT"
                >
                  <b-form-select
                    id="TT"
                    v-model="userForm.transaction_type"
                    :options="transactionTypeOptions"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Active"
                label-for="active"
              >
                <b-form-checkbox
                  id="active"
                  v-model="userForm.is_active"
                  checked="true"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div v-if="validationErrors.photo">
                <p
                  class="text-danger"
                >
                  {{ validationErrors.photo.toString() }}
                </p>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                @click.prevent="addVoucher()"
              >
                Save
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email } from '@validations'
import axios from 'axios'
// import vSelect from 'vue-select'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToggleButton from 'vue-js-toggle-button'
import _ from 'lodash'
import handleFormData from '@/global-compositions/handleFormData'
import voucher from '../vouchers'
// import store from '@/store'

Vue.use(ToggleButton)

export default {
  // components: { vSelect },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      merchants: [],
      categories: [],
      test: '',
      value: '',
      loader: false,
      validationErrors: {},
      merchant_id: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    pinToggleIcon() {
      return this.pinFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'userForm.merchant_id': function (value) {
      if (value) {
        if (this.validationErrors.merchant_id) {
          this.validationErrors.merchant_id = ''
        }
      }
    },
  },
  setup() {
    const {
      userForm, typeOptions, amountpercentage, transactionTypeOptions,
    } = voucher()
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      userForm,
      typeOptions,
      amountpercentage,
      transactionTypeOptions,
      formData,
      setFormData,
    }
  },
  mounted() {
    this.showUser()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.userForm.photo = e.target.files[0]
      // console.log(e)
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    selectMerchant(value) {
      this.userForm.merchant_id = this.merchants.find(owner => owner.id === value.id).id
    },
    selectCategory(value) {
      this.userForm.category_id = this.categories.find(owner => owner.id === value.id).id
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true)
        this.search(loading, search)
      }
    },
    // eslint-disable-next-line func-names
    search: _.debounce(function (loading, search) {
      axios.get(`get-merchants?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.merchants = res.data.data
        }
        loading(false)
      })
    }, 500),
    showUser() {
      if (this.$route.params.id) {
        axios.get(`vouchers/${this.$route.params.id}`).then(res => {
          this.merchant_id = res.data?.data.merchant_name
          this.userForm = res.data.data
          this.userForm.event_expire_date = res.data.data.event_expire_date.slice(0, 10)
        })
      } else {
        return false
      }
      return true
    },
    addVoucher() {
      if (this.$route.params.id) {
        this.loader = true
        if (this.userForm.is_active === true) {
          this.userForm.is_active = 1
        } else {
          this.userForm.is_active = 0
        }
        this.setFormData(this.userForm)
        this.formData.append('_method', 'PUT')
        axios.post(`vouchers/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'vouchers' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            if (this.userForm.is_active === true) {
              this.userForm.is_active = 1
            } else {
              this.userForm.is_active = 0
            }
            this.setFormData(this.userForm)
            axios.post('vouchers', this.formData).then(res => {
              if (res.status === 200 || res.status === 201) {
                this.$router.push({ name: 'vouchers' })
                this.$toasted.show('Created Successfully', {
                  position: 'top-center',
                  duration: 3000,
                })
              }
            }).catch(error => {
              Object.assign(this.validationErrors, error.response.data.errors)
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.order-type label.btn.btn-primary.active {
    background-color: #0074c7 !important;
}
</style>
